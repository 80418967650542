import { Component, OnInit } from '@angular/core';
import { QuestionService } from '../../service/question.service';
import { Question } from '../../model/question';

@Component({
  selector: 'app-recentquestions',
  templateUrl: './recentquestions.component.html',
  styleUrls: ['./recentquestions.component.scss']
})
export class RecentquestionsComponent implements OnInit {
  questions: Question[];
  answers = [];

  constructor(public questionservice: QuestionService) { }

  ngOnInit() {
    this.loadQuestions();

  }

  loadQuestions() {
    this.questionservice.getQuestions({limit: 2, sort: '-date'}).subscribe((res) => {
      this.questions = res;
    });
  }
}
