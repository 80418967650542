import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Observable} from 'rxjs';
import { Question } from '../model/question';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private http: HttpClient,
    private apiservice: ApiService,

    ) {}

    newQuestion(question) {
      return new Question(question._id, question.user, question.text, question.answers, question.date);
    }

    getQuestions(params = {}): Observable<Question[]> {
        return this.http.get<Question[]>(this.apiservice.url + '/questions', {params} )
        .pipe(map((questions: Question[]) =>
        questions.map(question => this.newQuestion(question))));
    }

    updateQuestion(question): Observable<Question[]> {
      return this.http.patch<Question[]>(this.apiservice.url + '/questions', question);
    }

    createQuestion(question): Observable<Question> {
      return this.http.post<Question>(this.apiservice.url + '/questions', question);
    }


  }
