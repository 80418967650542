import { Category } from './category';
import { User } from './user';

export class Vet {
  constructor(
// tslint:disable-next-line: variable-name
    public _id: string,
    public name: string,
    public profilepicture: string,
    public adress: string,
    public phone: number,
    public celphone: number,
    public lat: number,
    public lng: number,
    public description: string,
    public web: string,
    public reviews: [{
       val: number,
       text: string,
       user: User,
       date: Date
    }],
    public categories: Category[],
    public state: number,
    public city: number,
    public schedule: {
      weekday: {
        start: {hour: number, minute: number},
        end: {hour: number, minute: number}
       },
      saturday: {
        start: {hour: number, minute: number},
        end: {hour: number, minute: number}
      },
      sunday: {
        start: {hour: number, minute: number},
        end: {hour: number, minute: number}
      }
    }
    ) {}


    getReviewsAvarage(): number {
      let sum = 0;
      this.reviews.forEach(review => {sum += review.val; });
      return Math.round(sum / this.reviews.length);
    }

    getReviews() {
      return this.reviews;
    }


    setDefaultSchedule() {
      const hour = 0;
      const minute = 0;
      this.schedule = {
        weekday: {
          start: {hour, minute},
          end: {hour, minute}
         },
        saturday: {
          start: {hour, minute},
          end: {hour, minute}
        },
        sunday: {
          start: {hour, minute},
          end: {hour, minute}
        }
      }
    }
}




