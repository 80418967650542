import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { State } from '../../model/state';
import { StateService } from '../../service/state.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stateselect',
  templateUrl: './stateselect.component.html',
  styleUrls: ['./stateselect.component.scss']
})

export class StateselectComponent implements OnInit {
  @Input() clearable: boolean;
  @Output() change = new EventEmitter();
  @Output() close = new EventEmitter();
  states: State[];
  selectedstate: string;
  constructor(public stateservice: StateService, public route: ActivatedRoute) { }


  ngOnInit() {
    this.loadStates();
  }


  loadStates() {
    this.stateservice.getStates().subscribe(res => {
      this.states = res;
      this.getDefaultState();
    });

  }

  changeState(state) {
    if (state) {
    this.change.emit(state);
    }
  }
  closeSelect() {
    this.close.emit();
  }

  // CARGA EL ESTADO PRINCIPAL SI ESTA DISPONIBLE EN LA URL
  getDefaultState() {
    this.route.queryParamMap.subscribe(params => {
      if (params.get('state')) {
      this.stateservice.getStates({name: params.get('state')}).subscribe(res => {
      this.selectedstate = res.map(state => state._id)[0];
      this.change.emit(res[0]);
      });
    }
  });
  }
}
