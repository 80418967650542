import { Component, OnInit, HostListener } from '@angular/core';
import { Vet } from '../../model/vet';
import { VetService } from '../../service/vet.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/service/category.service';
import { StateService } from 'src/app/service/state.service';

@Component({
  selector: 'app-vetspage',
  templateUrl: './vetspage.component.html',
  styleUrls: ['./vetspage.component.scss']
})

export class VetspageComponent implements OnInit {
  vets: Vet[];
  title: string;
  activevet: string;



  constructor(
  public vetservice: VetService, public route: ActivatedRoute, public categoryservice: CategoryService, public stateservice: StateService){}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.keys.length === 0) {
        this.loadVets({});
      }
    });
  }

  loadVets(query) {
    this.vetservice.getVets(query).subscribe((res) => {
      this.vets = res;
    });
  }

  updateHover(vet) {
    this.activevet = vet;
  }


  updateTitle(title) {
   this.title = title;
  }
}
