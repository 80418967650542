import { Injectable } from '@angular/core';
import { UserService } from './service/user.service';
import { User } from './model/user';
import { Router } from '@angular/router';

@Injectable()
export class Environment {
  logged: boolean;
  loaded: boolean;
  user: User;
  constructor(public userservice: UserService, public router: Router) {
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
    this.logged = false;
  }

login(token: string) {
  localStorage.setItem('token', token);
  this.checkLogged();
  this.router.navigate(['/']);
}


checkLogged() {
    if (localStorage.getItem('token')) {
      this.userservice.login_check().subscribe(res => {
        if (res) {
          this.logged = true;
          this.user = this.userservice.createUser(res);
          this.loaded = true;
        }
      }, err => {
        localStorage.clear();
        this.logged = false;
      });
    } else {
      this.logged = false;
      this.loaded = true;
    }
  }
}
