import { Component, OnInit, Input } from '@angular/core';
import { Vet } from '../../model/vet';

@Component({
  selector: 'app-vetsmap',
  templateUrl: './vetsmap.component.html',
  styleUrls: ['./vetsmap.component.scss'],
})

export class VetsmapComponent implements OnInit {
  @Input() vets: Vet[];
  @Input() fitBounds = true;
  @Input() centerPoint: any;
  @Input() zoom: number;
  @Input() activevet: string;

  constructor() {
    this.centerPoint = {lat: 0, lng: 0};
  }

  ngOnInit() {
  }


  isInfoWindowOpen(marker) {
    if (marker === this.activevet) {
      return true;
    } else {
      return false;
    }
  }

}
