import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-registerpage',
  templateUrl: './registerpage.component.html',
  styleUrls: ['./registerpage.component.scss']
})
export class RegisterpageComponent implements OnInit {
  user: User;
  errors: string;
  formSubmit = false;
  terms1: boolean;


  constructor(public userservice: UserService, private router: Router) {
    this.user = userservice.createUser({});
  }

  ngOnInit() {
  }



  registrar() {
    this.userservice.register(this.user).subscribe(res => {
      this.formSubmit = true;
      this.router.navigate(['/login']);
    }, err => {
      this.showError(err.error.message);
    });
  }



  showError(error: string) {
    this.errors = error;
  }

}
