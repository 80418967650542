import { Injectable } from '@angular/core';
import { Environment } from '../environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(public env: Environment, public route: Router) {
  }

  checkOwn(id) {
      if (typeof this.env.user === 'undefined' || this.env.user.vet.name === id) {
        this.route.navigate(['/denied']);
      }
  }
}


