import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homesearch',
  templateUrl: './homesearch.component.html',
  styleUrls: ['./homesearch.component.scss']
})
export class HomesearchComponent implements OnInit {
  category: string;
  state: string;

  constructor() { }

  ngOnInit() {
  }

  stateChange(state) {
    this.state = state.name;

  }

  categoryChange(category) {
    this.category = category.name;
  }

}
