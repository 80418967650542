import { Component, OnInit } from '@angular/core';
import { State } from '../../model/state';
import { Category } from '../../model/category';
import { StateService } from '../../service/state.service';
import { CategoryService } from '../../service/category.service';

@Component({
  selector: 'app-searchby',
  templateUrl: './searchby.component.html',
  styleUrls: ['./searchby.component.scss']
})
export class SearchbyComponent implements OnInit {

  states: State[];
  categorys: Category[];

  constructor(public stateservice: StateService, public categoryservice: CategoryService) { }

  ngOnInit() {
    this.loadStates();
    this.loadCategorys();
  }

  loadStates() {
    this.stateservice.getStates().subscribe((res) => {
      this.states = res;
    });
  }

  loadCategorys() {
    this.categoryservice.getCategorys().subscribe((res) =>{
      this.categorys = res;
    });

  }
}
