import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Vet } from '../../model/vet';
import { User } from '../../model/user';
import { VetService } from 'src/app/service/vet.service';
import { Router } from '@angular/router';
import { CityselectComponent } from 'src/app/components/cityselect/cityselect.component';

@Component({
  selector: 'app-registervetpage',
  templateUrl: './registervetpage.component.html',
  styleUrls: ['./registervetpage.component.scss']
})
export class RegistervetpageComponent implements OnInit {
  user: User;
  vet: Vet;
  citys: string[];
  error: string;
  formSubmit: boolean;
  localerror: boolean;

  constructor(public userservice: UserService, public vetservice: VetService, private router: Router) {
    this.user = userservice.createUser({});
    this.vet = vetservice.createVet({});
  }

  ngOnInit() {
  }

  register() {
    this.user.vet = this.vet;
    this.userservice.register(this.user).subscribe(res => {
      this.formSubmit = true;
      this.router.navigate(['/login']);
    }, err => {
      this.showError(err.error.message);
    });
  }


  localValidation() {
    if (this.vet.state && this.vet.city) {
      this.localerror = false;
      return true;
    } else {
      this.localerror = true;
      return false;
    }
  }


  changeState(state) {
    this.citys = state.citys;
    this.vet.state = state._id;
    this.localValidation();
  }

  changeCategory(category) {
    this.vet.categories.push(category._id);
  }

  changeCity(city) {
    this.vet.city = city._id;
    this.localValidation();

  }

  showError(error: string) {
    this.error = error;
  }

}
