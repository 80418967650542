import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { VetprofilepageComponent } from './pages/vetprofilepage/vetprofilepage.component';
import { VetspageComponent } from './pages/vetspage/vetspage.component';
import {  QuestionpageComponent } from './pages/questionpage/questionpage.component';
import {  QuestionspageComponent } from './pages/questionspage/questionspage.component';
import { AllquestionpageComponent } from './pages/allquestionpage/allquestionpage.component';
import { RegisterpageComponent } from './pages/registerpage/registerpage.component';
import { LoginpageComponent } from './pages/loginpage/loginpage.component';
import { RegistervetpageComponent } from './pages/registervetpage/registervetpage.component';
import { VeteditprofilepageComponent } from './pages/veteditprofilepage/veteditprofilepage.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { MyaccountComponent } from './pages/myaccount/myaccount.component';
import { DeniedComponent } from './pages/denied/denied.component';
import { P404Component } from './pages/p404/p404.component';

const routes: Routes = [
  { path: '', component: HomepageComponent},
  { path: 'vet/:name', component: VetprofilepageComponent},
  { path: 'vet/edit/:id', component: VeteditprofilepageComponent},
  { path: 'vets', component: VetspageComponent},
  { path: 'questions', component:  QuestionspageComponent},
  { path: 'questions/all', component:  AllquestionpageComponent},
  { path: 'question/:id', component:  QuestionpageComponent},
  { path: 'register', component: RegisterpageComponent},
  { path: 'login', component: LoginpageComponent},
  { path: 'register/vet', component: RegistervetpageComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: 'myaccount', component: MyaccountComponent},
  { path: 'denied', component: DeniedComponent},
  { path: '**', component: P404Component}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
