import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-cityselect',
  templateUrl: './cityselect.component.html',
  styleUrls: ['./cityselect.component.scss']
})
export class CityselectComponent implements OnInit {
  @Input() citys;
  @Input() clearable: boolean;
  @Output() change = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  changeCity(city){
    this.change.emit(city);
  }


}
