import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editprofilepage',
  templateUrl: './editprofilepage.component.html',
  styleUrls: ['./editprofilepage.component.scss']
})
export class EditprofilepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
