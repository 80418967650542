import { Component, OnInit } from '@angular/core';
import { Question } from '../../model/question';
import { QuestionService } from '../../service/question.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from '../../environment';

@Component({
  selector: 'app-questionpage',
  templateUrl: './questionpage.component.html',
  styleUrls: ['./questionpage.component.scss']
})

export class QuestionpageComponent implements OnInit {
  question: Question;
  newanswertxt: string;
  relatedquestions: Question[];
  answersent: boolean;

  constructor(public questionservice: QuestionService,  private route: ActivatedRoute, public env: Environment) { }

  ngOnInit() {
    this.loadQuestion(this.route.snapshot.params.id);
    this.loadRelated();
  }

  loadQuestion(id) {
    this.questionservice.getQuestions({_id: id}).subscribe(res =>{
      this.question = res[0];
    });
  }

  loadRelated() {
    this.questionservice.getQuestions().subscribe(res => {
      this.relatedquestions = res;
    });
  }

  newAnswer() {
    if (this.newanswertxt.length > 2) {
    this.question.answers.push({vet: this.env.user.vet, text: this.newanswertxt, date: new Date(), best: false});
    this.newanswertxt = '';
    this.answersent = true;
    this.questionservice.updateQuestion(this.question).subscribe(res => {
    });
    }
  }
}
