import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { HomesearchComponent } from "./components/homesearch/homesearch.component";
import { VeterinariosmasvistosComponent } from "./components/veterinariosmasvistos/veterinariosmasvistos.component";
import { SearchbyComponent } from "./components/searchby/searchby.component";
import { FeaturedCategoriesComponent } from "./components/featuredcategories/featuredcategories.component";
import { HttpClientModule } from "@angular/common/http";
import { VetprofilepageComponent } from "./pages/vetprofilepage/vetprofilepage.component";
import { AgmCoreModule } from "@agm/core";
import { RecenthomeComponent } from "./components/recenthome/recenthome.component";
import { RecentquestionsComponent } from "./components/recentquestions/recentquestions.component";
import { RecentreviewsComponent } from "./components/recentreviews/recentreviews.component";
import { VetlistingComponent } from "./components/vetlisting/vetlisting.component";
import { VetspageComponent } from "./pages/vetspage/vetspage.component";
import { VetsmapComponent } from "./components/vetsmap/vetsmap.component";
import { RatingComponent } from "./components/rating/rating.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { VetfiltersComponent } from "./components/vetfilters/vetfilters.component";
import { QuestionspageComponent } from "./pages/questionspage/questionspage.component";
import { QuestionformComponent } from "./components/questionform/questionform.component";
import { QuestionlistingComponent } from "./components/questionlisting/questionlisting.component";
import { AllquestionpageComponent } from "./pages/allquestionpage/allquestionpage.component";
import { QuestionComponent } from "./components/question/question.component";
import { QuestionpageComponent } from "./pages/questionpage/questionpage.component";
import { RegisterpageComponent } from "./pages/registerpage/registerpage.component";
import { LoginpageComponent } from "./pages/loginpage/loginpage.component";
import { Environment } from "./environment";
import { RegistervetpageComponent } from "./pages/registervetpage/registervetpage.component";
import { CategoryselectComponent } from "./components/categoryselect/categoryselect.component";
import { StateselectComponent } from "./components/stateselect/stateselect.component";
import { CityselectComponent } from "./components/cityselect/cityselect.component";
import { EditprofilepageComponent } from "./pages/editprofilepage/editprofilepage.component";
import { VeteditprofilepageComponent } from "./pages/veteditprofilepage/veteditprofilepage.component";
import { ImageuploaderComponent } from "./components/imageuploader/imageuploader.component";
import { ImageComponent } from "./components/image/image.component";
import { StarratingComponent } from "./components/starrating/starrating.component";
import { AppinfoComponent } from "./components/appinfo/appinfo.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { LOCALE_ID } from "@angular/core";
import localeesMX from "@angular/common/locales/es-MX";
import { registerLocaleData } from "@angular/common";
import { OpenvetsComponent } from "./components/openvets/openvets.component";
import { RelativeTimeFilterPipe } from "./pipes/relativetime.pipe";
import { TimeWithCeroPipe } from "./pipes/timewithcero.pipe";
import { MyaccountComponent } from "./pages/myaccount/myaccount.component";
import { DeniedComponent } from "./pages/denied/denied.component";
import { P404Component } from "./pages/p404/p404.component";
import { MustMatchDirective } from "./directives/mustmatch.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ParallaxDirective } from "./directives/parallax.directive";
import { BannervetsComponent } from "./components/bannervets/bannervets.component";
import { DeviceDetectorModule } from "ngx-device-detector";

registerLocaleData(localeesMX);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    HomesearchComponent,
    VeterinariosmasvistosComponent,
    SearchbyComponent,
    FeaturedCategoriesComponent,
    VetprofilepageComponent,
    RecenthomeComponent,
    RecentquestionsComponent,
    RecentreviewsComponent,
    VetlistingComponent,
    VetspageComponent,
    VetsmapComponent,
    RatingComponent,
    VetfiltersComponent,
    QuestionspageComponent,
    QuestionformComponent,
    QuestionlistingComponent,
    AllquestionpageComponent,
    QuestionComponent,
    QuestionpageComponent,
    RegisterpageComponent,
    LoginpageComponent,
    RegistervetpageComponent,
    CategoryselectComponent,
    StateselectComponent,
    CityselectComponent,
    EditprofilepageComponent,
    VeteditprofilepageComponent,
    ImageuploaderComponent,
    ImageComponent,
    StarratingComponent,
    AppinfoComponent,
    TermsComponent,
    PrivacyComponent,
    OpenvetsComponent,
    RelativeTimeFilterPipe,
    TimeWithCeroPipe,
    MyaccountComponent,
    DeniedComponent,
    P404Component,
    MustMatchDirective,
    ParallaxDirective,
    BannervetsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    DeviceDetectorModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyA-Bzz78K5i1v5BKJdSQgG4jQ-6nf9hclA",
    }),
  ],
  providers: [Environment, { provide: LOCALE_ID, useValue: "es-MX" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
