import { Component, OnInit } from '@angular/core';
import { Vet } from 'src/app/model/vet';
import { VetService } from 'src/app/service/vet.service';

@Component({
  selector: 'app-openvets',
  templateUrl: './openvets.component.html',
  styleUrls: ['./openvets.component.scss']
})
export class OpenvetsComponent implements OnInit {
  vets: Vet[];
  fitBounds: boolean;
  centerPoint: any;
  zoom: number;

  constructor(public vetsservice: VetService) {}

  ngOnInit() {
    this.loadVets();
    this.findPos();
  }

  findPos() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        this.centerPoint = {lat:  position.coords.latitude, lng:  position.coords.longitude };
        this.fitBounds = false;
        this.zoom = 14;
      });
  }
}

  loadVets() {
    this.vetsservice.getVets().subscribe(r => {
      this.vets = r;
    });
  }
}
