import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Category } from '../model/category';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private apiservice: ApiService
    ) {}

    createCategory(category) {
      return new Category(category._id, category.id, category.featured, category.order, category.name);
    }

    getCategorys(params = {}): Observable<Category[]> {
      return this.http.get<Category[]>(this.apiservice.url + '/categories', {params})
      .pipe(map((categorys: Category[]) => categorys.map(category =>
      this.createCategory(category))));
    }

}
