import { Component, OnInit} from '@angular/core';
import { VetService } from '../../service/vet.service';
import { Vet } from '../../model/vet';
import { GeocodingService } from '../../service/geocoder.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SecurityService } from 'src/app/service/security.service';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-veteditprofilepage',
  templateUrl: './veteditprofilepage.component.html',
  styleUrls: ['./veteditprofilepage.component.scss'],
})
export class VeteditprofilepageComponent implements OnInit {
  vet: Vet;
  map: any;
  mapObj: any;
  afuConfig: {};

  constructor(public vetservice: VetService,
              public geocodingservice: GeocodingService,
              public router: ActivatedRoute,
              public securityservice: SecurityService,
              public route: Router) {
                this.map = {lng: 0, lat: 0};
              }

  ngOnInit() {
    this.LoadVet(this.router.snapshot.params.id);
  }

  LoadVet(id: string) {
    this.vetservice.getVets({_id: id}).subscribe((resp) => {
      this.securityservice.checkOwn(this.router.snapshot.params.id);
      this.vet = resp[0];
      if (typeof this.vet.schedule === 'undefined') {
        this.vet.setDefaultSchedule();
      }
    });
  }

  changeCategory(categories) {
    this.vet.categories = categories;
  }

  centerChange(map) {
    this.vet.lat = map.lat;
    this.vet.lng = map.lng;
  }

  setMap(map){
    this.mapObj = map;
    this.mapObj.setCenter({lat: this.vet.lat, lng: this.vet.lng});
  }


  changeAdress() {
    this.geocodingservice.codeAddress(this.vet.adress).subscribe(res => {
      this.vet.lat = res[0].geometry.location.lat();
      this.vet.lng = res[0].geometry.location.lng();
      this.mapObj.setCenter({lat: this.vet.lat, lng: this.vet.lng});
    });
  }

  ppChange(image) {
    this.vet.profilepicture = image.filename;
  }

  save() {
    console.log(this.vet);
    this.vetservice.updateVet(this.vet).subscribe(res => {
      this.route.navigate(['/vet/', this.vet.name]);
    });
  }
}
