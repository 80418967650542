import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { Category } from '../../model/category';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categoryselect',
  templateUrl: './categoryselect.component.html',
  styleUrls: ['./categoryselect.component.scss']
})
export class CategoryselectComponent implements OnInit {
  @Output() change = new EventEmitter();
  @Input() selectedcategories: Category[];
  @Input() multi = true;
  categories: Category[];
  selectedcategoriesstrings: string[];


  constructor(public categoryservice: CategoryService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.loadCategorys();
  }

  loadCategorys() {
    this.categoryservice.getCategorys({sort: 'order'}).subscribe(res => {
      this.categories = res;
      this.getDefaultCategory();
      if (this.selectedcategories) {
      this.selectedcategoriesstrings = this.selectedcategories.map(cat => cat._id);
      }
    });
  }

  changeCategory(category) {
    this.change.emit(category);
  }


  // CARGA CATEGORIA DEFAULT SI ESTA DISPONIBLE EN LA URL
  getDefaultCategory() {
    this.route.queryParamMap.subscribe(params => {
      if (params.get('category')) {
      this.categoryservice.getCategorys({name: params.get('category')}).subscribe(res => {
      this.selectedcategoriesstrings = res.map(cat => cat._id);
      this.change.emit(res);
      });
    }
  });
  }
}
