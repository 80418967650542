import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Category } from '../model/category';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
    private apiservice: ApiService
    ) {}

    uploadFile(formData): Observable<{}> {
      return this.http.post(this.apiservice.fileuploadurl, formData)
      .pipe(map(files => files)
      );
    }
}
