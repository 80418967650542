import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Environment } from '../../environment';

@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit {
  user: any = {};
  error: string;
  token: string;

  constructor(public userservice: UserService, private env: Environment) { }

  ngOnInit() {

  }

  login() {
    this.userservice.login(this.user).subscribe(res => {
      this.env.login(res['token']);
    },
    err => {
      this.error = err.error.message;
    });
  }
}
