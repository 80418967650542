import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // Configuración de la api
  url = "https://api.petpat.mx";
  fileuploadurl = this.url + "/files";
  publicurl = this.url + "/public/";
  httpOptions: {};

  constructor() {
    this.setHTTPOptions();
  }

  setHTTPOptions() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
    };
  }
}
