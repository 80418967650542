import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective {

  @Input('ratio') parallaxRatio: number = 1;
  @Input('background') background: boolean = true;
  @Input('rotate') rotate: boolean = false;
  @Input('top') initialTop: number = 0;
  @Input('left') initialLeft: number = 0;
  @Input('right') initialRight: number = 0;
  @Input('direction') direction: string = 'top';
  @Input('double') double: boolean = false;



  constructor(private eleRef: ElementRef, private DeviceDetector: DeviceDetectorService) {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (this.DeviceDetector.isDesktop()) {
      if (this.background) {
        if (this.double) {
        // tslint:disable-next-line: max-line-length
        this.eleRef.nativeElement.style.backgroundPosition = '0px ' + (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px , 99% ' +  (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px';
        } else {
        this.eleRef.nativeElement.style.backgroundPositionY = (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px';
        }
      } else {
        switch (this.direction) {

        case 'left':
        this.eleRef.nativeElement.style.left = (this.initialLeft - (window.scrollY * this.parallaxRatio)) + 'px';
        break;

        case 'right':
        this.eleRef.nativeElement.style.right = (this.initialRight - (window.scrollY * this.parallaxRatio)) + 'px';
        break;

        case 'top':
        this.eleRef.nativeElement.style.top = (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px';
        break;
      }


      }
      if (this.rotate) {
        this.eleRef.nativeElement.style.transform = 'rotate(' + (window.scrollY / 4) + 'deg)';
      }
    }
  }
}
