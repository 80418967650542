import { Component, OnInit } from '@angular/core';
import { VetService } from '../../service/vet.service';

@Component({
  selector: 'app-recentreviews',
  templateUrl: './recentreviews.component.html',
  styleUrls: ['./recentreviews.component.scss']
})
export class RecentreviewsComponent implements OnInit {
  reviews: [{review: any, vet: any}];
  // Configuracion de OWL Carousel
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    margin: 20,
    center: false,
    dotsEach: 2,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      }
    },
    nav: false
  };

  constructor(public vetservice: VetService) {}

  ngOnInit() {
    this.loadReviews();
  }



  loadReviews() {
    let first = true;
    this.vetservice.getVets({limit: 4, sort: '-reviews.date'}).subscribe(res => {
      res.forEach(vet => {
        vet.reviews.forEach(review => {
          if (first) {
            this.reviews = [{review, vet}];
            first = false;
          } else {
            this.reviews.push({review, vet});
          }
        });
      });
    });
  }
}
