import { PipeTransform, Pipe} from '@angular/core';

@Pipe({
    name: 'timewithcero'
})

export class TimeWithCeroPipe implements PipeTransform{

transform(numberr: number): string {
    if (numberr < 10) {
    return '0' + numberr;
    } else {
      return numberr.toString();
    }
  }
}
