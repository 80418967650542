import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Category } from '../../model/category';
import { State } from 'src/app/model/state';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vetfilters',
  templateUrl: './vetfilters.component.html',
  styleUrls: ['./vetfilters.component.scss']
})
export class VetfiltersComponent implements OnInit {
  @Output() filterschange = new EventEmitter();
  @Output() titlechange = new EventEmitter();

  citys: string[];
  categories: Category[];
  state: State;
  query = {};

constructor(public route: ActivatedRoute) {}

  ngOnInit() {
  }

  changeState(state) {
    this.filter(state, 'state');
    this.citys = state.citys;
  }


  // FILTRA EL LISTADO POR CADA PARAMETRO QUE SE RECIBE
  filter(info, key) {
    let value;
    if (typeof info === 'undefined' || info.length === 0) {
      value = '';
    } else {

    if (Array.isArray(info)) {
        value = info[0]._id;
      } else {
        value = info._id;
      }
    }
    this.query[key] = value;
    this.generateTitle(info, key);
    this.filterschange.emit(this.query);
    }



    generateTitle(info, key) {
      let state;
      let category;
      let title;

      if (key === 'state') {
        state = info.name;
      } else if (key === 'categories') {
        category = '';
        info.forEach(c => {
          category += c.name;
          if (info.length > 1 ) {
            category += ', ';
          }
        });
      }

      if (typeof category === 'undefined') {
        category = 'Cualquier servicio';
      } else if ( typeof state === 'undefined') {
        state = 'Cualquier lugar';
      }

      title = category + ' en ' + state;

      this.titlechange.emit(title);
    }

}
