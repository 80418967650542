import { Vet } from './vet';

export class User {
  constructor(
    public _id: string,
    public email: string,
    public username: string,
    public password: string,
    public role: string,
    public vet: Vet,
    public firstname: string,
    public secondname: string
    ) {}

    isVet() {
      if (this.role === 'Vet') {
        return true;
      } else {
        return false;
      }
    }
}
