import { Component, OnInit } from "@angular/core";
import { Environment } from "../../environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isCollapsed: boolean = true; // Estado inicial del colapso

  constructor(public env: Environment) {}

  ngOnInit() {
    this.env.checkLogged();
  }

  toggleNavbar(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
