import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() image: string;
  PublicUrl: string;

  constructor(public apiservice: ApiService) {
    this.PublicUrl = this.apiservice.publicurl;
  }

  ngOnInit() {
  }

}
