import { Component, OnInit, Input } from '@angular/core';
import { Question } from 'src/app/model/question';
import { ApiService } from 'src/app/service/api.service';
import { QuestionService } from 'src/app/service/question.service';
import { Environment } from 'src/app/environment';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() all: boolean;
  constructor(public apiservice: ApiService, public questionservice: QuestionService, public env: Environment) {
  }

  ngOnInit() {
  }

  selectBestAnswer(answer) {
    this.questionservice.updateQuestion(this.question).subscribe(res => {
    });
  }
}
