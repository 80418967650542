
import { Vet } from '../model/vet';
import { User } from '../model/user';

export class Question {
  constructor(
    public _id: string,
    public user: User,
    public text: string = '',
    public answers: [{
      vet: Vet,
      text: string,
      date: Date,
      best: boolean
    }],
    public date: Date
  ) {}

haveBest() {
  return this.answers.find(answer => answer.best === true);
}

}
