import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { State } from '../model/state';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(
    private http: HttpClient,
    private apiservice: ApiService
    ) {}

    createState(state) {
      return new State(state._id, state.id, state.name, state.citys);
    }

    getStates(params = {}): Observable<State[]> {
      return this.http.get<State[]>(this.apiservice.url + '/states', {params})
      .pipe(map((states: State[]) => states.map(state =>
      this.createState(state))));
    }
}
