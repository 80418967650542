import { Component, OnInit } from '@angular/core';
import { VetService } from '../../service/vet.service';
import { Vet } from '../../model/vet';
import { ActivatedRoute } from '@angular/router';
import { Environment } from '../../environment';
import { Question } from 'src/app/model/question';
import { QuestionService } from 'src/app/service/question.service';


@Component({
  selector: 'app-vetprofilepage',
  templateUrl: './vetprofilepage.component.html',
  styleUrls: ['./vetprofilepage.component.scss'],
})
export class VetprofilepageComponent implements OnInit {
  vet: Vet;
  reviews: any;
  avarage: number;
  newrating: number;
  newratingtext: string;
  reviewing: boolean;
  questions: Question[];

// tslint:disable-next-line: max-line-length
  constructor(public vetservice: VetService, private route: ActivatedRoute, public env: Environment, public questionservice: QuestionService) { }

  ngOnInit() {
    this.LoadData(this.route.snapshot.params.name);
  }

  LoadData(name: string) {
    this.LoadVet(name);
  }

  newratingChange(value) {
    this.newrating = value;
  }

  sendRating() {
    this.vet.reviews.push({val: this.newrating, text: this.newratingtext, user: this.env.user, date: new Date()});
    this.vetservice.updateVet(this.vet).subscribe( res => {
      this.reviewing = false;
      this.newratingtext = '';
    });
  }

  startReview() {
    if (this.reviewing) {
      this.sendRating();
    } else {
    this.reviewing = true;
    }
  }

  LoadVet(name: string) {
    this.vetservice.getVets({name}).subscribe((resp) => {
      this.vet = resp[0];
      this.questionservice.getQuestions({'answers.vet': this.vet._id}).subscribe((resp2) => {
        this.questions = resp2;
      });
    });
  }
}
