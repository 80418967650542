import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FileService } from '../../service/file.service';

@Component({
  selector: 'app-imageuploader',
  templateUrl: './imageuploader.component.html',
  styleUrls: ['./imageuploader.component.scss']
})
export class ImageuploaderComponent implements OnInit {
  @Output() fileschanged = new EventEmitter();
  @ViewChild('filebutton', { static: true }) filebutton: ElementRef;
  filesToUpload: Array<File> = [];
  constructor(public fileservice: FileService) {}


  ngOnInit() {

  }

  openFile() {
    const el: HTMLElement = this.filebutton.nativeElement as HTMLElement;
    el.click();
  }

  cambioFile() {
    this.delay(1000).then( () => {
      this.upload();
    })
  }

  upload() {
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    formData.append('avatar', files[0], files[0].name);
    this.fileservice.uploadFile(formData).subscribe(res => {
    this.fileschanged.emit(res);
    });
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = fileInput.target.files as Array<File>;
  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
    }

}
