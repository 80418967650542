import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private apiservice: ApiService
    ) {}

    createUser(user) {
     return new User(user._id, user.email, user.username, user.passwword, user.role, user.vet, user.firstname, user.secondname);
    }

    register(user: User): Observable<{}> {
      return this.http.post(this.apiservice.url + '/users', user);
    }

    login(user: any): Observable<{}> {
      return this.http.post(this.apiservice.url + '/users/login', user);
    }

    login_check(): Observable<{}> {
      this.apiservice.setHTTPOptions();
      return this.http.get(this.apiservice.url + '/users/login_check', this.apiservice.httpOptions);
    }
}
