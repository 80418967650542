import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Vet } from '../model/vet';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VetService {
  constructor(
    private http: HttpClient,
    private apiservice: ApiService
    ) {}

    createVet(vet) {
// tslint:disable-next-line: max-line-length
      return new Vet(vet._id, vet.name, vet.profilepicture, vet.adress, vet.phone, vet.celphone, vet.lat, vet.lng, vet.description, vet.web, vet.reviews, vet.categories, vet.state, vet.city, vet.schedule);
    }

    getVets(params = {}): Observable<Vet[]> {
      return this.http.get<Vet[]>(this.apiservice.url + '/vets', {params})
      .pipe(map((vets: Vet[]) => vets.map(vet =>
      this.createVet(vet))));
    }

    updateVet(vet): Observable<Vet[]> {
      return this.http.patch<Vet[]>(this.apiservice.url + '/vets', vet);
    }
}
