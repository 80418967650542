import { Component, OnInit, Input} from '@angular/core';
import { QuestionService } from '../../service/question.service';
import { Question } from '../../model/question';
import { Environment } from 'src/app/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-questionform',
  templateUrl: './questionform.component.html',
  styleUrls: ['./questionform.component.scss']
})
export class QuestionformComponent implements OnInit {
  @Input() showinfo: boolean;
  newQuestion: Question;

  constructor(public questionservice: QuestionService, public env: Environment, public router: Router) {
    this.newQuestion = this.questionservice.newQuestion({});
   }

  ngOnInit() {
  }

  sendQuestion() {
    this.newQuestion.user = this.env.user;
    this.questionservice.createQuestion(this.newQuestion).subscribe( res => {
    this.router.navigate(['question/', res._id]);
    });
  }

}
