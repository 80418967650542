import { Component, OnInit} from '@angular/core';
import { VetService } from '../../service/vet.service';
import { Vet } from '../..//model/vet';
import { ViewEncapsulation } from '@angular/core';



@Component({
  selector: 'app-veterinariosmasvistos',
  templateUrl: './veterinariosmasvistos.component.html',
  styleUrls: ['./veterinariosmasvistos.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VeterinariosmasvistosComponent implements OnInit{
  Vets: Vet[];

  // Configuracion de OWL Carousel
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    margin: 20,
    center: true,
    dotsEach: 1,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      }
    },
    nav: false
  };

  constructor(public vetservice: VetService
    ) {}


  // Evento que se ejecuta al inicio de la vista
  ngOnInit() {
      this.loadVets();
  }



  // Carga Los Veterinarios
  loadVets() {
    this.vetservice.getVets().subscribe((resp: Vet[]) => {
      this.Vets = resp;
    });
  }
}
