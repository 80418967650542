import { Component, OnInit } from '@angular/core';
import { State } from '../../model/state';
import { Category } from '../../model/category';
import { StateService } from '../../service/state.service';
import { CategoryService } from '../../service/category.service';

@Component({
  selector: 'app-featuredcategories',
  templateUrl: './featuredcategories.component.html',
  styleUrls: ['./featuredcategories.component.scss']
})
export class FeaturedCategoriesComponent implements OnInit {

  states: State[];
  categorys: Category[];

  constructor(public stateservice: StateService, public categoryservice: CategoryService) { }

  ngOnInit() {
    this.loadCategorys();
  }


  loadCategorys() {
    this.categoryservice.getCategorys({featured: 1}).subscribe((res) =>{
      this.categorys = res;
    });

  }
}
