import{PipeTransform,Pipe} from '@angular/core';

// Epochs
const epochs: any = [
  ['Año', 31536000],
  ['Mes', 2592000],
  ['Dia', 86400],
  ['Hora', 3600],
  ['Minuto', 60],
  ['Segundo', 1]
];

@Pipe({
    name: 'relative'
})

export class RelativeTimeFilterPipe implements PipeTransform{

  getDuration(timeAgoInSeconds: number) {
    for (let [name, seconds] of epochs) {
        let interval = Math.floor(timeAgoInSeconds / seconds);

        if (interval >= 1) {
            return {
                interval: interval,
                epoch: name
            };
        }
    }
    return {
        interval: 0,
        epoch: 'Segundo'
    };
};

transform(dateStamp: number): string {
    let timeAgoInSeconds = Math.floor((new Date().getTime() - new Date(dateStamp).getTime()) / 1000);
    let {interval, epoch} = this.getDuration(timeAgoInSeconds);
    let suffix = interval === 1 ? '' : 's';
    return `Hace ${interval} ${epoch}${suffix}`;
  }
}
