import { Component } from '@angular/core';
import { UserService } from './service/user.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WePets';
  ngOnInit() {
    AOS.init();
  }
}

