// ref
import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import {} from 'googlemaps';
declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  geocoder: any;

    constructor(public mapsAPILoader: MapsAPILoader) {
      this.mapsAPILoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
      });
    }

    geocode(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult[]> {
        return Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
            this.geocoder.geocode({ location: latLng }, (
                (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        observer.next(results);
                        observer.complete();
                    } else {
                        observer.error(status);
                    }
                })
            );
        });
    }


    codeAddress(address: string): Observable<google.maps.GeocoderResult[]> {
        return Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
            this.geocoder.geocode({ address: address }, (
                (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        observer.next(results);
                        observer.complete();
                    } else {

                    }
                })
            );
        });
    }

}
