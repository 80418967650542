import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Vet } from '../../model/vet';
import { ApiService } from 'src/app/service/api.service';



@Component({
  selector: 'app-vetlisting',
  templateUrl: './vetlisting.component.html',
  styleUrls: ['./vetlisting.component.scss']
})


export class VetlistingComponent implements OnInit {
  @Input() vets: Vet[];
  @Output() hoverVet = new EventEmitter();

  constructor(public apiservice: ApiService) { }

  ngOnInit() {
  }


  newhoverVet(vet) {
    this.hoverVet.emit(vet);
  }


}
